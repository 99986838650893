




















import Vue from 'vue'
import { SfBanner, SfButton } from '@storefront-ui/vue'

export default Vue.extend({
  name: 'Banner',
  components: {
    SfBanner,
    SfButton,
  },
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    description: {
      type: String,
    },
    button_text: {
      type: String,
    },
    button_link: {
      type: String,
    },
    image: {
      type: Object,
    },
    background: {
      type: String,
    },
  },
})
